import Vue from 'vue'
import {
	List,
	Popup,
	Toast,
	Button,
	Swipe,
	SwipeItem,
	Progress,
	Collapse,
	CollapseItem,
} from 'vant'
Vue.use(List)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Progress)
Vue.use(Collapse)
Vue.use(CollapseItem)

<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'app',
	methods: {
		// 添加判断方法
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		}
	},
	mounted() {
		if (this.isMobile()) {
			// alert("手机端");
			this.$router.replace('/mobile')
		} else {
			// alert("pc端");
			this.$router.replace('/')
		}
		window.onload = function() {
			document.addEventListener('touchstart', function(event) {
				if (event.touches.length > 1) {
					event.preventDefault()
				}
			})
			document.addEventListener('gesturestart', function(event) {
				event.preventDefault()
			})
		}
	}
}
</script>

<style></style>

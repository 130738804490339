import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	// mode: 'history',
	routes: [
		{ path: '/', redirect: '/pc' },
		{
			path: '/pc',
			name: 'pc',
			component: () => import('@/views/pc/index.vue'),
			redirect: '/home',
			children: [
				{
					path: '/home',
					name: 'home',
					component: () => import('@/views/pc/home.vue')
				}
			]
		},
		{
			path: '/mobile',
			name: 'mobile',
			redirect: '/mobileHome',
			component: () => import('@/views/mobile/mobileIndex.vue'),
			children: [
				{
					path: '/mobileHome',
					name: 'mobileHome',
					component: () => import('@/views/mobile/mobileHome.vue')
				}
			]
		}
	],
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})
